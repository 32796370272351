<template>
  <div class="quiz-overview">
    <div
      :style="{ color: gmPrimaryColor }"
      class="sw-block-subtitle text-center"
    >
      {{ $vuetify.lang.t("$vuetify.confirmationHeadline") }}
    </div>
    <div class="sw-mb-5 sw-text text-center">
      {{ $vuetify.lang.t("$vuetify.editInformationText") }}
    </div>
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- answers -->
    <div class="sw-text font-weight-thin">
      {{ $vuetify.lang.t("$vuetify.yourAnswers") }}
    </div>
    <v-row
      v-for="(attribute, i) in transformedUserAttributes"
      :key="`answer-${i}`"
      no-gutters
      class="sw-text"
    >
      <v-col cols="9" class="font-weight-regular">{{ attribute.name }}</v-col>
      <v-col cols="3" class="text-right">
        <a
          :style="{ color: gmSecondaryColor }"
          @click.stop="changeAnswer(attribute)"
          >{{ $vuetify.lang.t("$vuetify.edit") }}</a
        >
      </v-col>
      <template v-if="attribute.type === 'options'">
        <v-col
          v-for="(answer, i) in attribute.answer"
          :key="`answer-${i}`"
          cols="12"
          class="font-weight-light"
        >
          {{ answer.name }}
          <span v-if="answer.price"
            >({{ answer.price / 100 }} {{ groupCurrency }})</span
          >
        </v-col>
      </template>
      <v-col
        v-if="attribute.type === 'varchar'"
        cols="12"
        class="font-weight-light"
      >
        {{ attribute.answer }}
      </v-col>
      <v-col
        v-if="attribute.type === 'text'"
        cols="12"
        class="font-weight-light"
      >
        <nl2br tag="p" :text="attribute.answer" />
      </v-col>
      <v-col
        v-if="attribute.type === 'datetime'"
        cols="12"
        class="font-weight-light"
      >
        {{ dateFormattedDate(attribute.answer) }}
      </v-col>
    </v-row>
    <!-- eo answers -->
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="next"
      >
        <span class="sw-px-2">
          {{ finishBtnText }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    isLoading: false,
    category: {},
    transformedUserAttributes: [],
    reservedUserAttributes: [],
    isBought: false,
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    plugin() {
      return this.$store?.getters["quiz/plugin"];
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    defaultTaxRate() {
      return this.$store?.getters?.group?.default_tax_rate;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    reservedPaymentTotal() {
      return this.reservedUserAttributes?.find(
        (el) => el.slug === `reserved_g${this.category?.id}_payment_total`,
      );
    },
    attribute: {
      get() {
        return this.$store?.getters["quiz/attribute"];
      },
      set(val) {
        this.$store.commit("quiz/SET_ATTRIBUTE", val);
      },
    },
    window: {
      get() {
        return this.$store?.getters["quiz/window"];
      },
      set(val) {
        this.$store?.commit("quiz/SET_WINDOW", val);
      },
    },
    dateFormattedDate() {
      return (date) => (date ? moment(date).format(this.dateFormat) : "");
    },
    finishBtnText() {
      if (
        this.plugin.attributes &&
        this.plugin.attributes.questionwizard_finish_button
      ) {
        return this.plugin.attributes.questionwizard_finish_button;
      }

      return this.$vuetify.lang.t("$vuetify.finishQuiz");
    },
  },
  watch: {
    window: {
      immediate: true,
      handler(val) {
        if (val === 1) this.fetchAll();
      },
    },
  },
  methods: {
    async getQuizCategory() {
      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        {
          params: {
            group_plugin_id: this.plugin?.id,
            per_page: 1,
          },
        },
      );

      if (!response?.data?.data?.[0]) return;

      this.category = response?.data?.data?.[0];
    },
    async listUserAttributes() {
      this.transformedUserAttributes = [];

      const params = {
        category_id: this.category.id,
        lang: this.appLanguage,
        per_page: 10000,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/list_related`,
        { params },
      );

      if (!response?.data?.data) return;

      for (const attribute of response?.data?.data) {
        const value = response?.data?.meta[attribute?.slug];

        attribute.meta_value = value;

        if (["varchar", "datetime", "text"].includes(attribute.type)) {
          attribute.answer = value;
        }

        if (attribute.type === "options" && attribute?.is_collection) {
          attribute.answer = attribute.options
            .filter((option) => value.includes(option.id))
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        }

        if (attribute.type === "options" && !attribute?.is_collection) {
          attribute.answer = attribute.options
            .filter((option) => value === option.id)
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        }

        this.transformedUserAttributes.push(attribute);
      }
    },
    async listReservedAttributes() {
      const params = {
        categories: this.category?.id,
        reserved_only: 1,
        per_page: 500,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes`,
        { params },
      );

      this.reservedUserAttributes = response?.data?.data;
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getQuizCategory();
        await this.listUserAttributes();
        await this.listReservedAttributes();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    changeAnswer(attribute) {
      this.attribute = attribute;
      this.user.attributes[attribute.slug] = attribute.meta_value;
      this.window = 0;
    },
    async hasPaid() {
      const response = await this.$http.get(`/auth/is_bought`, {
        params: { product: `Attribute:${this.reservedPaymentTotal.id}` },
      });

      this.isBought = response.data.data.is_bought;
    },
    async pay() {
      await this.$http.post(`/auth/buy`, {
        product: `Attribute:${this.reservedPaymentTotal.id}`,
      });
    },
    async next() {
      try {
        this.isLoading = true;

        await this.pay();
        await this.hasPaid();

        if (!this.isBought) {
          this.isLoading = false;
          return;
        }

        this.isLoading = false;

        this.window = 2;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    this.$http.get(`/settings/public/global.date_format`).then((res) => {
      this.dateFormat = res.data.data.value;
    });
  },
};
</script>

<style lang="scss" scoped></style>
