<template>
  <div class="quiz-done">
    <template v-if="plugin.attributes">
      <div class="sw-block-subtitle text-center">
        {{ plugin.attributes.questionwizard_thank_you_headline }}
      </div>
      <v-card flat max-width="428" class="sw-mx-auto text-center">
        <nl2br
          tag="p"
          :text="plugin.attributes.questionwizard_thank_you_content"
        />
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    plugin() {
      return this.$store?.getters["quiz/plugin"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
